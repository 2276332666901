@use '@angular/material' as mat;
@use 'variables';

.mdc-tab-indicator__content {
  border-color: mat.m2-get-color-from-palette(variables.$psl-accent-color, 500) !important;
}

mat-tab-group {
  .mat-mdc-tab-labels {
    width: 51%;
  }

  .mdc-tab__content {
    min-height: inherit;
    text-transform: uppercase;
  }

  .mat-mdc-tab {
    min-height: 4rem;
    min-width: 190px !important;
  }
}

mat-tab-group {
  .mat-tab-header {
    //overflow: visible;
    border-bottom: 0;
  }

  .mat-tab-list {
    background: variables.$tabs-bg;
    border-bottom: 2px solid mat.m2-get-color-from-palette(variables.$psl-app-accent);
  }

  .mat-tab-label {
    height: auto;
    border-bottom: 2px solid transparent;
    border-left: 2px solid transparent;
    border-right: 2px solid transparent;
    border-top: 2px solid transparent;
    flex-grow: 1 1 0;
    margin-bottom: -2px;
    overflow: visible;
    color: variables.$dark-blue;

    svg {
      width: 2.4rem;
      height: 2.4rem;
    }

    .positive svg {
      fill: mat.m2-get-color-from-palette(variables.$psl-app-green, A400);
    }

    .neutral svg {
      fill: #cdcdcd;
    }

    .negative svg {
      fill: mat.m2-get-color-from-palette(variables.$psl-app-warn);
    }
  }

  .mat-tab-label-active {
    background: #fff;
    border-left-color: mat.m2-get-color-from-palette(variables.$psl-app-accent);
    border-top-color: mat.m2-get-color-from-palette(variables.$psl-app-accent);
    border-right-color: mat.m2-get-color-from-palette(variables.$psl-app-accent);
    border-bottom-color: #fff; //mat.get-color-from-palette(variables.$psl-app-accent);
    opacity: 1;
    position: relative;

    /*
        &:after {
            position: absolute;
            display: block;
            content: '';
            left: 0;
            right: 0;
            bottom: -5px;
            height: 10px;
            background: #fff;
        }
        */
  }

  .mat-tab-label-content {
    padding: 5px 0;
    // flex-direction: column;
    text-transform: uppercase;
    font-weight: 500;
    display: block !important;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .mat-ink-bar {
    display: none;
  }
}
