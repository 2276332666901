@use '@angular/material' as mat;
@use 'variables';

.table {
  table {
    width: 100%;
    border-collapse: collapse;
  }

  th {
    text-transform: uppercase;
    font-size: 0.8rem;
    text-align: left;
  }

  tr.active {
    border-top: 2px solid mat.m2-get-color-from-palette(variables.$psl-app-accent) !important;
    border-bottom: 2px solid mat.m2-get-color-from-palette(variables.$psl-app-accent) !important;
    border-left: 2px solid mat.m2-get-color-from-palette(variables.$psl-app-accent) !important;
    border-right: 2px solid mat.m2-get-color-from-palette(variables.$psl-app-accent) !important;
  }

  td {
    border-top: 1px solid variables.$border-color;
    font-weight: normal;
  }

  th,
  td {
    padding: 10px 20px;
  }
}
