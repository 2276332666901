.custom-spinner .p-progressspinner-circle {
  animation: custom-progress-spinner-dash 1.5s ease-in-out infinite,
    custom-progress-spinner-color 6s ease-in-out infinite !important;
}

@keyframes custom-progress-spinner-color {
  100%,
  0% {
    stroke: var(--p-sky-500);
  }
  40% {
    stroke: var(--p-sky-500);
  }
  66% {
    stroke: var(--p-sky-500);
  }
  80%,
  90% {
    stroke: var(--p-sky-500);
  }
}

@keyframes custom-progress-spinner-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

p-progress-spinner {
  display: flex;
  margin: 0 auto;
  position: relative;
  top: 45%;
}
