mat-form-field {
    margin-bottom: 15px;
}

element {
    display: none;
}

.tox {
    .tox-dialog__body-nav {
        display:none !important;
    }
}