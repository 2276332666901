@use '@angular/material' as mat;

$body-bg: #f0f5f7;
$tabs-bg: $body-bg;
$border-color: #d1d9e0;
$important-color: #ff515b;
$invalid-cell-color: #ff1744;
$muted: #536a84;

$dark-blue: #232f42;

$psl-custom-typography: mat.m2-define-typography-config(
  $font-family: 'Roboto, sans-serif',
  $headline-5: mat.m2-define-typography-level(32px, 48px, 700, 'Roboto, sans-serif', normal),
  $headline-6: mat.m2-define-typography-level(20px, 32px, 500, 'Roboto, sans-serif', normal),
  $body-1: mat.m2-define-typography-level(16px, 24px, 500, 'Roboto, sans-serif', normal),
  $body-2: mat.m2-define-typography-level(16px, 24px, 500, 'Roboto, sans-serif', normal),
  $button: mat.m2-define-typography-level(14px, 24px, 500, 'Roboto, sans-serif', normal),
  $caption: mat.m2-define-typography-level(14px, 24px, 500, 'Roboto, sans-serif', normal),
);

// generated these colors using https://angular-md-color.com/#/
$psl-primary-color: (
  50: #4f6a95,
  100: #465e85,
  200: #3e5374,
  300: #354763,
  400: #2c3b53,
  500: #232f42,
  600: #1a2331,
  700: #111721,
  800: #080b10,
  900: #000000,
  A100: #5876a6,
  A200: #6884af,
  A400: #7991b8,
  A700: #000000,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: white,
    A400: white,
    A700: white,
  ),
);

$psl-accent-color: (
  50: #0040aa,
  100: #0049c3,
  200: #0053dd,
  300: #005cf6,
  400: #116aff,
  500: #2a7aff,
  600: #5d9aff,
  700: #77aaff,
  800: #90baff,
  900: #aacaff,
  A100: #5d9aff,
  A200: #448aff,
  A400: #2a7aff,
  A700: #c3daff,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: white,
    A400: white,
    A700: white,
  ),
);

$psl-other-color: (
  50: #ff515b,
  100: #ffa500,
  200: #ce93d8,
  300: #ba68c8,
  400: #ab47bc,
  500: #9c27b0,
  600: #8e24aa,
  700: #7b1fa2,
  800: #6a1b9a,
  900: #4a148c,
  A100: #ea80fc,
  A200: #e040fb,
  A400: #d500f9,
  A700: #aa00ff,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: white,
    A400: white,
    A700: white,
  ),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$psl-app-primary: mat.m2-define-palette($psl-primary-color);
$psl-app-accent: mat.m2-define-palette($psl-accent-color);
//$psl-app-primary: mat.define-palette(mat.$indigo-palette);
//$psl-app-accent:  mat.define-palette(mat.$blue-palette, A200, A100, A400);
$psl-app-green: mat.m2-define-palette(mat.$m2-green-palette);

// The warn palette is optional (defaults to red).
$psl-app-warn: mat.m2-define-palette(mat.$m2-red-palette, A400);

// Create the theme object (a Sass map containing all of the palettes).
$psl-app-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $psl-app-primary,
      accent: $psl-app-accent,
      warn: $psl-app-warn,
    ),
    typography: $psl-custom-typography,
    density: 0,
  )
);
