/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
// @use "@angular/material/prebuilt-themes/pink-bluegrey.css";

@use 'scss/variables';
@use 'scss/fonts';
@use 'scss/theme';
@use 'scss/layout';
@use 'scss/forms';
@use 'scss/tabs';
@use 'scss/tables';
@use 'scss/charts';
@use 'scss/spinner';

@use 'ag-grid-community/dist/styles/ag-grid.css';
@use 'ag-grid-community/dist/styles/ag-theme-alpine.css';

a,
a:visited {
  color: mat.m2-get-color-from-palette(variables.$psl-app-accent);
  text-decoration: none;
  fill: mat.m2-get-color-from-palette(variables.$psl-app-accent);
}

a:hover,
a:visited:hover,
a:active,
a:focus {
  text-decoration: underline;
  color: scale(mat.m2-get-color-from-palette(variables.$psl-app-accent), -10%);
}

button.mat-menu-item {
  line-height: 24px !important;
}

a.mat-menu-item > mat-icon {
  margin-bottom: 14px;
}

.no-wrap {
  white-space: nowrap;
}

.snackbar-no-padding {
  padding: 0px 0px 0px 0px !important;
}

.cdk-overlay-pane {
  .mat-snack-bar-container {
    max-width: 46vw;
  }
}

.danger {
  color: red;
}

.mat-loading .mat-button-wrapper {
  visibility: hidden;
}

.mat-tooltip {
  font-size: 14px;
}
.dont-show-checkbox {
  label {
    color: mat.m2-get-contrast-color-from-palette(variables.$psl-primary-color, 300);
  }
}

::ng-deep .tooltip-autoscale {
  max-width: unset !important;
  width: unset !important;
}

@media screen and (max-width: 991px) {
  .mat-mdc-paginator-container {
    justify-content: center !important;

    .mat-mdc-paginator-range-label {
      margin: 0 !important;
      white-space: nowrap;
    }
  }
}
